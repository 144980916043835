import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Swal from "sweetalert2";

import app from "../../../firebase-config";

function DataBase() {
  const [ultimoEstado, setUltimoEstado] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [detalle, setDetalle] = useState("");
  const [estado, setEstado] = useState("");
  const [errorEstate, setErrorEstate] = useState(false);
  const [errorFecha, setErrorFecha] = useState(false);
  registerLocale("es", es);

  function confirmDialog(deta, estad, milisegundos, fecha) {
    Swal.fire({
      title: "Agregar registro:\n" + deta + "\n" + fecha,
      text:
        "La siguiente acción agregará un estado directamente. ¿Esta seguro que desea continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Si, continuar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        spinnerModal();
        app
          .firestore()
          .collection("sensor")
          .add({ detalle: deta, estado: estad, hora: milisegundos.toString() })
          .then(() => {
            Swal.fire("Agregado!", "El estado ha cambiado.", "success");
          })
          .catch(() => {
            Swal.fire("Error!", "No se pudo agregar el estado.", "error");
          });

        app
          .firestore()
          .collection("notification")
          .doc("sensor")
          .set({ detalle: deta, estado: estad, hora: milisegundos.toString() });
      }
    });
  }
  function spinnerModal() {
    let timerInterval;
    Swal.fire({
      title: "Agregando registro",
      html: "Espere un momento por favor.",
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  }

  async function agregarRegistro(event) {
    event.preventDefault();
    if (detalle === "") {
      setErrorEstate(true);
    } else {
      if (!errorEstate && !errorFecha) {
        confirmDialog(
          detalle,
          estado,
          startDate.getTime(),
          startDate.toLocaleTimeString()
        );
      }
    }
  }

  function setFechas() {
    const hoy = new Date();
    console.log(hoy);
    setStartDate(hoy);
    document.getElementById("resgistroHr").value = hoy.getHours();
    document.getElementById("resgistroMin").value = hoy.getMinutes();
    document.getElementById("resgistroSeg").value = hoy.getSeconds();
    if (ultimoEstado.fechaMilisec >= hoy.getTime()) {
      setErrorFecha(true);
    } else {
      setErrorFecha(false);
    }
  }
  function setFechasPick(date) {
    if (ultimoEstado.fechaMilisec >= date) {
      setStartDate(date);
      setErrorFecha(true);
    } else {
      setStartDate(date);
      setErrorFecha(false);
    }
  }

  async function getData() {
    await app
      .firestore()
      .collection("sensor")
      .orderBy("hora", "desc")
      .limit(1)
      .onSnapshot((snap) => {
        var docs = {};
        snap.forEach((doc) => {
          const diaNombre = new Date(parseInt(doc.data().hora)).getDay();
          docs = {
            fechaMilisec: doc.data().hora,
            estado: doc.data().estado,
            detalle: doc.data().detalle,
            mes: new Date(parseInt(doc.data().hora)).getMonth() + 1,
            date: new Date(parseInt(doc.data().hora)).getDate(),
            dia: new Date(parseInt(doc.data().hora)).toLocaleDateString(),
            diaNombre:
              diaNombre === 1
                ? "Lunes"
                : diaNombre === 2
                  ? "Martes"
                  : diaNombre === 3
                    ? "Miercoles"
                    : diaNombre === 4
                      ? "Jueves"
                      : diaNombre === 5
                        ? "Viernes"
                        : diaNombre === 6
                          ? "Sábado"
                          : "Domingo",
            hora: new Date(parseInt(doc.data().hora)).getHours(),
            minutos: new Date(parseInt(doc.data().hora)).getMinutes(),
          };
        });
        setUltimoEstado(docs);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const db = (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">
                {" "}
                {/*Top Navigation*/} <small></small>
              </h1>
            </div>
            {/* /.col */}
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>

      <div className="content">
        <div className="container">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Agregar Registro de Estado</h3>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="ml-3">Última Actualización</label>
                {ultimoEstado !== {} ? (
                  <div
                    className="alert alert-warning mx-3"
                    style={{
                      backgroundColor:
                        ultimoEstado.estado === 5
                          ? "rgba(255,0,0,0.9)"
                          : ultimoEstado.estado === 4
                            ? "rgba(255,165,0,0.9)"
                            : ultimoEstado.estado === 3
                              ? "rgba(255,255,0,0.9)"
                              : ultimoEstado.estado === 2
                                ? "rgba(0,187,45,0.9)"
                                : "rgba(128,128,128,0.9)",
                    }}
                  >
                    <h5>
                      <i className="icon fas fa-exclamation-triangle" />{" "}
                      {ultimoEstado.detalle}
                    </h5>
                    {ultimoEstado.diaNombre + "  " + ultimoEstado.date}, a las{" "}
                    {ultimoEstado.hora}:{ultimoEstado.minutos}hrs.
                  </div>
                ) : (
                  <div>Cargando...</div>
                )}
              </div>
              <br></br>
              <div className="row">
                <div className="col-sm-4 ml-3">
                  <div className="card">
                    <div
                      className="card-body"
                      style={{ backgroundColor: "rgba(255,255,0,0.1)" }}
                    >
                      <label>Estado que se Registrará:</label>
                      <div className="input-group row ml-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-bolt" />
                          </span>
                        </div>
                        <div className="col-sm-7">
                          <input
                            style={{ textAlign: "center" }}
                            className="form-control"
                            value={detalle.toUpperCase()}
                            placeholder="estado..."
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                      <br></br>
                      <label>Fecha/Hora que se Registrará:</label>
                      <div className="input-group row ml-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-calendar-alt" />
                          </span>
                        </div>
                        <div className="col-sm-7">
                          <input
                            style={{ textAlign: "center" }}
                            className="form-control"
                            value={
                              startDate.getDate() +
                              " / " +
                              parseInt(startDate.getMonth() + 1) +
                              " / " +
                              startDate.getFullYear()
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="input-group row mt-2 ml-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock" />
                          </span>
                        </div>
                        <div className="col-sm-3">
                          <input
                            id="resgistroHr"
                            style={{ textAlign: "center" }}
                            className="form-control"
                            value={startDate.getHours()}
                            disabled
                            type="text"
                          />
                        </div>
                        <h4>:</h4>
                        <div className="col-sm-3">
                          <input
                            id="resgistroMin"
                            style={{ textAlign: "center" }}
                            className="form-control"
                            value={startDate.getMinutes()}
                            disabled
                            type="text"
                          />
                        </div>
                        <h4>:</h4>
                        <div className="col-sm-3">
                          <input
                            id="resgistroSeg"
                            style={{ textAlign: "center" }}
                            className="form-control"
                            value={startDate.getSeconds()}
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                      {/* /.input group */}
                    </div>
                  </div>
                </div>

                <div className="col-sm-7 ml-3">
                  <div className="row">
                    <div className="col-sm-5 pl-4">
                      {/* radio */}
                      <label>Seleccione el Estado</label>
                      {ultimoEstado !== {} ? (
                        <div className="form-group ml-2">
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input custom-control-input-danger"
                              type="radio"
                              id="customRadio1"
                              name="detalle"
                              value="Falla"
                              disabled={ultimoEstado.estado === 1}
                              onChange={(e) => {
                                setDetalle(e.currentTarget.value);
                                setEstado(1);
                                setErrorEstate(false);
                              }}
                            />
                            <label
                              htmlFor="customRadio1"
                              className="custom-control-label"
                            >
                              Falla
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input custom-control-input-danger"
                              type="radio"
                              id="customRadio2"
                              name="detalle"
                              value="No Relámpago Detectado."
                              disabled={ultimoEstado.estado === 2}
                              onChange={(e) => {
                                setDetalle(e.currentTarget.value);
                                setEstado(2);
                                setErrorEstate(false);
                              }}
                            />
                            <label
                              htmlFor="customRadio2"
                              className="custom-control-label"
                            >
                              No Relámpago Detectado.
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input custom-control-input-danger"
                              type="radio"
                              id="customRadio3"
                              name="detalle"
                              value="Precaución"
                              disabled={ultimoEstado.estado === 3}
                              onChange={(e) => {
                                setDetalle(e.currentTarget.value);
                                setEstado(3);
                                setErrorEstate(false);
                              }}
                            />
                            <label
                              htmlFor="customRadio3"
                              className="custom-control-label"
                            >
                              Precaución
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input custom-control-input-danger"
                              type="radio"
                              id="customRadio4"
                              name="detalle"
                              value="Aviso"
                              disabled={ultimoEstado.estado === 4}
                              onChange={(e) => {
                                setDetalle(e.currentTarget.value);
                                setEstado(4);
                                setErrorEstate(false);
                              }}
                            />
                            <label
                              htmlFor="customRadio4"
                              className="custom-control-label"
                            >
                              Aviso
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input custom-control-input-danger"
                              type="radio"
                              id="customRadio5"
                              name="detalle"
                              value="Alarma"
                              disabled={ultimoEstado.estado === 5}
                              onChange={(e) => {
                                setDetalle(e.currentTarget.value);
                                setEstado(5);
                                setErrorEstate(false);
                              }}
                            />
                            <label
                              htmlFor="customRadio5"
                              className="custom-control-label"
                            >
                              Alarma
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div>Cargando...</div>
                      )}
                      {errorEstate ? (
                        <h6 className="text-danger col-md-9 mt-4 ml-3">
                          *Seleccione un estado
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-sm-7">
                      <div className="form-group">
                        <label>Seleccione Fecha/Hora de Registro:</label>
                        <div
                          className="input-group date ml-2"
                          id="reservationdate"
                          data-target-input="nearest"
                        >
                          <DatePicker
                            selected={startDate}
                            dateFormat="Pp"
                            timeFormat="HH:mm"
                            locale="es"
                            minDate={
                              new Date(parseInt(ultimoEstado.fechaMilisec))
                            }
                            maxDate={new Date()}
                            showTimeSelect
                            timeIntervals={1}
                            timeCaption="Hora"
                            onSelect={(date) => setFechasPick(date)}
                            onChange={(date) => setFechasPick(date)}
                          />
                          <div
                            className="input-group-append"
                            data-target="#reservationdate"
                            data-toggle="datetimepicker"
                          >
                            <div className="input-group-text">
                              <i className="fa fa-calendar" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-group">
                          <label>Establecer Fecha:</label>
                          <div className="input-group row">
                            <Popup
                              trigger={
                                <button
                                  onClick={() => setFechas()}
                                  className="btn btn-primary btn-sm ml-3"
                                >
                                  <i className="fas fa-clock mr-2"></i>
                                  Fecha Actual
                                </button>
                              }
                              position="right  center"
                              on={["hover", "focus"]}
                              arrow="center center"
                            >
                              <span className="ml-10">
                                Establece los campos del nuevo registro a la
                                fecha actual.
                              </span>
                            </Popup>
                          </div>
                          {errorFecha ? (
                            <h6 className="text-danger col-md-9 mt-4 ml-3">
                              *Establezca una fecha superior a la del último
                              estado.
                            </h6>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={(e) => agregarRegistro(e)}
                  className="btn btn-info col-sm col-md-2 mt-4 ml-4"
                >
                  <i className="fas fa-database mr-2"></i>
                  Agregar Registro
                </button>
                <h6 className="text-danger col-md-9 mt-4 ml-3">
                  *Recuerde que esta acción agregará un registro en la BD y
                  cambiará el estado actual. Usar con precaución.
                  <br></br>*Esto alertará con una notificación a todos los
                  usuarios conectados.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return db;
}

export default DataBase;
