import React, { useState, useEffect } from "react";

import app from "../../../firebase-config";

function EstadoCard() {
  const [estados, setEstados] = useState([]);
  const [fecha, setFecha] = useState([]);

  async function getData() {
    await app
      .firestore()
      .collection("sensor")
      .orderBy("hora", "desc")
      .limit(3)
      .onSnapshot((snap) => {
        const fecDocs = [];
        const docs = [];
        snap.forEach((doc) => {
          const diaNombre = new Date(parseInt(doc.data().hora)).getDay();
          fecDocs.push({
            mes: new Date(parseInt(doc.data().hora)).getMonth() + 1,
            dia: new Date(parseInt(doc.data().hora)).toLocaleDateString(),
            diaNombre:
              diaNombre === 1
                ? "Lunes"
                : diaNombre === 2
                  ? "Martes"
                  : diaNombre === 3
                    ? "Miercoles"
                    : diaNombre === 4
                      ? "Jueves"
                      : diaNombre === 5
                        ? "Viernes"
                        : diaNombre === 6
                          ? "Sábado"
                          : "Domingo",
            hora: new Date(parseInt(doc.data().hora)).getHours(),
            minutos: new Date(parseInt(doc.data().hora)).getMinutes(),
          });
          docs.push(doc.data());
        }, setEstados(docs));
        setFecha(fecDocs);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const estadoCard = (
    <div className="card card-primary card-outline">
      <div className="card-header">
        <h5 className="card-title m-0">Estado</h5>
      </div>
      <div className="card-body">
        <h6 className="card-title">Estado actual de tormenta</h6>
        <p className="card-text"></p>
        {estados.length <= 2 ? (
          <div>Cargando...</div>
        ) : (
          <div className="row">
            <div className="col-md-7 col-sm-6 col-12">
              <div
                className="small-box"
                style={{
                  backgroundColor:
                    estados[0].estado === 5
                      ? "#d32f2f"
                      : estados[0].estado === 4
                        ? "#FB8C00"
                        : estados[0].estado === 3
                          ? "#FDD835"
                          : estados[0].estado === 2
                            ? "#388E3C"
                            : "#757575",
                }}
              >
                <div className="inner" style={{ color: "white", }}>
                  <p
                    style={{ fontSize: 35, fontWeight: "bold" }}
                  >
                    {estados[0].detalle}
                  </p>
                  <p>
                    {fecha[0].diaNombre} {fecha[0].dia}
                    <br></br> A la(s) {fecha[0].hora} : {fecha[0].minutos} hrs.
                  </p>
                </div>
                <div className="icon ">
                  <i
                    className={
                      estados[0].estado === 1
                        ? "fas fa-exclamation-circle mt-4"
                        : estados[0].estado === 2
                          ? "fas fa-check mt-4"
                          : estados[0].estado === 3
                            ? "fas fa-exclamation mt-4"
                            : estados[0].estado === 4
                              ? "fas fa-exclamation-triangle mt-4"
                              : estados[0].estado === 5
                                ? "fas fa-bolt mt-4"
                                : null
                    }
                  />
                </div>
                <div className="small-box-footer">
                  Estado actual
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-6 col-12">
              <div className="row">
                <div className="info-box col-12">
                  <span className="info-box-icon bg-info">
                    <i className="fas fa-arrow-left" />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">{estados[1].detalle}</span>
                    <span className="info-box-number">
                      {fecha[1].diaNombre} {fecha[1].dia}, {fecha[1].hora}:
                      {fecha[1].minutos} hrs.
                    </span>
                  </div>
                  {/* /.info-box-content */}
                </div>
                <div className="info-box col-12">
                  <span className="info-box-icon bg-info">
                    <i className="fas fa-arrow-up" />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">{estados[2].detalle}</span>
                    <span className="info-box-number">
                      {fecha[2].diaNombre} {fecha[1].dia}, {fecha[2].hora}:
                      {fecha[2].minutos} hrs.
                    </span>
                  </div>
                  {/* /.info-box-content */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  return estadoCard;
}

export default EstadoCard;
